import { Modal } from "antd";
import React from "react";
import "./OfferPopup.css";

export default function OfferPopup({ onCancel, offerBannerImage }) {
  return (
    <>
      <Modal
        className="offer-modal"
        // title="Vertically centered modal dialog"
        centered
        visible={true}
        onCancel={onCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      ><a href="/reservation">
          <img
            src={offerBannerImage[0]?.fld_banner_image}
            alt="offerbanner"
            // src="https://www.masalaofindia.com/static/media/holiday2.3e4e4ea7b42fbfd38ef4.jpg"
            className="img-fluid w-100 h-100"
          />
        </a>
      </Modal>
    </>
  );
}
